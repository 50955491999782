.tabsContainer {
  width: 100%;
}

.tabs {
  display: flex;
  border-bottom: 1px solid rgba(214, 214, 214, 1);
  .tabTitle {
    padding: 10px 20px;
    @media (max-width: 550px) {
      padding: 10px 5px;
    }
    color: #8b909a;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
  }
  .active {
    border-bottom: 2px solid #11103d;
    color: #11103d;
  }
}

.tab-content {
  margin-top: 20px;
}
