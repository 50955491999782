.main {
  .header {
    display: flex;
    align-items: center;
    gap: 15px;
    h3 {
      color: #071952;
    }
  }
  nav {
    ul {
      display: flex;
      gap: 100px;
      list-style: none;
      font-size: large;
      font-weight: bold;
      li {
        padding: 10px;
      }
      li:hover {
        background-color: rgb(212, 212, 212);
        cursor: pointer;
      }
    }
  }
}

.overviewMain {
  display: flex;
  background-color: white;
  justify-content: space-around;
  padding: 10px;
}

.listing {
  height: calc(85vh - 150px);
  justify-content: space-between;
}

.billingCards {
  background-color: white;
}
