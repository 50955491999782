.select {
  display: block;
  width: 100%;
  padding: 0.45rem 2.7rem 0.45rem 0.9rem;
  -moz-padding-start: calc(0.9rem - 3px);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4b4b5a;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 0.9rem center;
  background-size: 14px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

.listing {
  min-height: calc(95vh - 150px);
  justify-content: space-between;
}

.trowClass {
  cursor: pointer;
}

.trowClass:hover {
  background-color: #eeeff0;
  cursor: pointer;
}

.billingCards {
  width: 350px;
}
